import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import ArticleBangla from "./views/ArticleBangla";
import ArticleEnglish from "./views/ArticleEnglish";
import Header from "./layout/Header";
import NotFound from "./views/NotFound";
import Login from "./page/Login";
import Dashboard from "./page/Dashboard";
import NormalContentWriter from "./page/NormalContentWriter";
import CreateSeries from "./page/CreateSeries";
import SeriesContentWrite from "./page/SeriesContentWrite";
import SeriesView from "./page/SeriesView";
import SeriesContentView from "./page/SeriesContentView";
import SeriesContentViewPublic from "./page/SeriesContentViewPublic";
import SeriesArticleEnglish from "./views/SeriesArticleEnglish";

export default function App() {
  const routes = [
    {
      href: "/",
      component: <Home />,
      header: true,
      footer: true,
    },
    {
      href: "/discussion/bangla/:did",
      component: <ArticleBangla />,
      header: true,
      footer: true,
    },
    {
      href: "/discussion/english/:did",
      component: <ArticleEnglish />,
      header: true,
      footer: true,
    },
    {
      href: "/discussion/series/english/:seriesID/:did",
      component: <SeriesArticleEnglish />,
      header: true,
      footer: true,
    },
    {
      href: "/discussion/series/bangla/:seriesID/:did",
      component: <ArticleBangla />,
      header: true,
      footer: true,
    },
    {
      href: "/series/english/:seriesID",
      component: <SeriesContentViewPublic />,
      header: true,
      footer: true,
    },
    {
      href: "/join",
      component: <Login />,
      header: true,
      footer: true,
    },
    {
      href: "/dashboard/",
      component: <Dashboard />,
      header: true,
      footer: true,
    },
    {
      href: "/dashboard/write/",
      component: <NormalContentWriter />,
      header: true,
      footer: true,
    },
    {
      href: "/dashboard/series/create",
      component: <CreateSeries />,
      header: true,
      footer: true,
    },
    {
      href: "/dashboard/series/",
      component: <SeriesView />,
      header: true,
      footer: true,
    },
    {
      href: "/dashboard/series/write/:seriesId",
      component: <SeriesContentWrite />,
      header: true,
      footer: true,
    },
    {
      href: "/dashboard/series/view/:seriesId",
      component: <SeriesContentView />,
      header: true,
      footer: true,
    },
    {
      href: "*",
      component: <NotFound />,
      header: false,
      footer: false,
    },
  ];

  return (
    <div className="App">
      <Routes>
        {routes &&
          React.Children.toArray(
            routes.map((route) => (
              <Route
                path={route?.href}
                element={
                  <>
                    {route?.header ? <Header /> : false}
                    {route?.component}
                    {/* {route?.footer ? <Footer /> : false} */}
                  </>
                }
              />
            ))
          )}
      </Routes>
    </div>
  );
}
