import React, { useEffect, useState } from "react";
import useApp from "../store";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../utils/firebase";
import LoadingComponent from "../views/LoadingComponent";
import Card from "../components/Card";

export default function SeriesView() {
  const { StoreSeriesData, loggedInStatus, profileData } = useApp();
  const navigate = useNavigate();
  const contentDocumentName = "series-paradox";

  const [data, setData] = useState();
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    try {
      async function dataCall() {
        let data = [];
        let q;
        if (profileData?.userType && profileData?.userType === "monopolist") {
          q = query(
            collection(db, contentDocumentName),
            orderBy("date", "desc")
          );
        } else {
          q = query(
            collection(db, contentDocumentName),
            where("writer", "==", profileData?.uid),
            orderBy("date", "desc")
          );
        }
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id });
        });
        return data;
      }

      Promise.resolve(dataCall()).then((value) => {
        setData(value);
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
    }
  }, [profileData?.userType]);

  useEffect(() => {
    !loggedInStatus && navigate("/join");
  }, [loggedInStatus]);

  return (
    <>
      <main className="ahj-series_view">
        {data && Array.isArray(data) && data.length > 0 ? (
          <div className="content">
            {React.Children.toArray(
              data.map((card) => (
                <Card
                  data={card}
                  type="series_article_preview"
                  language={card?.language}
                  optionalFunction={() => StoreSeriesData(card)}
                />
              ))
            )}
          </div>
        ) : (
          <p>No series found</p>
        )}
      </main>
      <LoadingComponent state={Loading} />
    </>
  );
}
