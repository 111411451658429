import React, { useEffect } from "react";
import useApp from "../store";
import { useNavigate, useParams } from "react-router-dom";
import { encodeTitleToURL } from "../utils/functions";
import SeriesContentWriter from "../views/SeriesContentWriter";

export default function SeriesContentWrite() {
  const { loggedInStatus, profileData } = useApp();
  const navigate = useNavigate();
  const path = useParams();
  const seriesHandle = encodeTitleToURL(path.seriesId);

  useEffect(() => {
    !loggedInStatus && navigate("/join");
  }, [loggedInStatus]);

  return (
    <div>
      {(profileData?.userType === "monopolist" ||
        profileData?.userType === "fascist") && (
        <>
          <SeriesContentWriter seriesId={seriesHandle} />
        </>
      )}
    </div>
  );
}
