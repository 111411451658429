import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useApp from "../store";

export default function Header() {
  const { profileData, loggedInStatus } = useApp();
  const navigate = useNavigate();
  // const router = useRouter();
  // const pathname = usePathname();

  // function handleClick() {
  //   const current = new URLSearchParams(Array.from(sp.entries()));
  //   current.set("lang", lang === "bn" ? "en" : "bn");
  //   const url = pathname?.replace("/", "") + "?" + current?.toString();
  //   router.push(url);
  // }

  // console.log(
  //   homeData?.[Object.keys(homeData)?.[Object.keys(homeData)?.length - 1]]
  //   // ?.july18?.en?.button?.link
  // );

  return (
    <header className="ahj-header fixed top-0 left-0 right-0 w-100">
      <div className="ahj-header-container">
        <div className="ahj-header-container-content">
          <Link to="/" className={`ahj-font-anek-bangla logo relative`}>
            <span>রাজ</span>
            নীতি
          </Link>

          <div className="right">
            {loggedInStatus ? (
              <div
                className="profile-icon"
                role="button"
                onClick={() => navigate("/dashboard/")}
              >
                {profileData?.photoURL ? (
                  <img
                    src={profileData?.photoURL}
                    alt={profileData?.displayName}
                  />
                ) : (
                  <span>{profileData?.displayName?.[0]}</span>
                )}
              </div>
            ) : (
              <Link to="/join" className="ahj-join">
                Join
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="ahj-header-line"></div>
    </header>
  );
}
