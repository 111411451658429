import React, { useEffect, useState } from "react";
import useApp from "../store";
import { useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../utils/firebase";
import { encodeTitleToURL } from "../utils/functions";
import Card from "../components/Card";
import { FaShare } from "react-icons/fa";

export default function SeriesContentViewPublic() {
  const { profileData } = useApp();
  const path = useParams();
  const seriesHandle = encodeTitleToURL(path.seriesID);
  const contentDocumentName = "series-paradox";
  const contentDocumentNameSecondary = "content-paradox";

  const [SeriesData, setSeriesData] = useState();
  const [data, setData] = useState();
  const [SeriesLoading, setSeriesLoading] = useState(false);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setSeriesLoading(true);
    const get = async () => {
      const docRef = doc(db, contentDocumentName, seriesHandle);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const sData = docSnap.data();
        setSeriesData(sData);
        setSeriesLoading(false);
      } else {
        console.error("No such document!");
        setSeriesLoading(false);
      }
    };
    seriesHandle && get();

    try {
      const subCollectionRef = collection(
        doc(db, contentDocumentName, seriesHandle),
        contentDocumentNameSecondary
      );
      let q;
      if (profileData?.userType && profileData?.userType === "monopolist") {
        q = query(subCollectionRef, orderBy("date", "desc"));
      } else {
        q = query(
          subCollectionRef,
          where("status", "==", "published"),
          orderBy("date")
        );
      }
      getDocs(q)
        .then((snapshot) => {
          let data = [];
          snapshot.docs.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id });
            console.log(doc.id, doc.data()); // Log each document's data
          });
          setData(data); // Assuming setData is a state setter function
          setLoading(false); // Assuming you are using a loading state
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
        });
    } catch (error) {
      console.error(error);
    }
  }, [profileData?.userType, seriesHandle]);

  console.log(SeriesData);

  return (
    <>
      <main className="ahj-series_view">
        <div
          className="background"
          // style={{ backgroundImage: `url(${SeriesData?.banner})` }}
        >
          <img src={SeriesData?.banner} alt={SeriesData?.title} />
        </div>
        <p className="details">{SeriesData?.overview}</p>
        {!Loading ? (
          data && Array.isArray(data) && data.length > 0 ? (
            <div className="content">
              {React.Children.toArray(
                data.map((card) => (
                  <Card
                    data={{ ...card, series: seriesHandle }}
                    type="standard_series"
                    language={card?.language}
                  />
                ))
              )}
            </div>
          ) : (
            <>
              <p style={{ padding: "24px 32px", textAlign: "center" }}>
                Stay Tuned, We Are Cooking The Contents...
              </p>
            </>
          )
        ) : (
          <div className="content">
            {React.Children.toArray(
              [...Array(8)].map((item) => (
                <Card type="loading" language={item} />
              ))
            )}
          </div>
        )}
        <button
          className="share-button"
          onClick={() => navigator.share({ url: `${window?.location?.href}` })}
        >
          <FaShare />
        </button>
      </main>
    </>
  );
}
