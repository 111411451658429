import { createContext, useContext, useReducer } from "react";
import reducer, { initialState } from "./reducer";
import { processProfileData } from "./helper/dataProcess";

const Context = createContext(initialState);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const userLogin = (uState, uData, uType) => {
    dispatch({
      type: "login",
      payload: uState,
    });
    dispatch({ type: "profileData", payload: processProfileData(uData) });
    dispatch({ type: "userIs", payload: uType });
    sessionStorage.loggedIn = uState ? "yes" : "no";
    sessionStorage.user = JSON.stringify(processProfileData(uData));
  };
  const userLogout = () => {
    dispatch({
      type: "login",
      payload: false,
    });
    dispatch({ type: "profileData", payload: null });
    dispatch({ type: "userIs", payload: undefined });
    sessionStorage.loggedIn = "no";
    sessionStorage.removeItem("user");
  };
  const updateUser = (data) => {
    dispatch({ type: "profileData", payload: processProfileData(data) });
    sessionStorage.user = JSON.stringify(processProfileData(data));
  };
  const LastUpdated = (data) => {
    dispatch({
      type: "lastUpdated",
      payload: data,
    });
  };
  const homeData = (data) => {
    dispatch({
      type: "homeData",
      payload: data,
    });
  };
  const StoreSeriesData = (data) => {
    dispatch({
      type: "seriesInformation",
      payload: data,
    });
  };

  const value = {
    ...state,
    userLogin,
    userLogout,
    updateUser,
    LastUpdated,
    homeData,
    StoreSeriesData,
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useApp = () => {
  const context = useContext(Context);
  return context;
};

export default useApp;
