import { collection, doc, setDoc } from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { db } from "../firebase";

export async function handleArticleUpload(
  image,
  image_type,
  id,
  profileData,
  image_path,
  article_path,
  setUploading,
  setImageError,
  setError,
  data
) {
  // image_type === "event" && e.preventDefault();
  if (
    (image_type === "event" && image?.target.files[0] !== undefined) ||
    (image_type === "file" && image !== undefined)
  ) {
    async function up() {
      setUploading(true);
      try {
        const newImageRef = ref(getStorage(), image_path);
        const fileSnapshot = await uploadBytesResumable(
          newImageRef,
          (image_type === "event" && image.target.files[0]) ||
            (image_type === "file" && image)
        );
        if (fileSnapshot && fileSnapshot.state !== "success") {
          throw new Error();
        }

        const publicImageUrl = await getDownloadURL(newImageRef);

        return publicImageUrl;
      } catch (error) {
        setImageError(error.message);
        return undefined;
      }
    }
    Promise.resolve(up()).then((value) => {
      setUploading(false);
      setImageError();
      let obj = JSON.parse(JSON.stringify(data));
      obj.banner = value;
      try {
        const docRef = doc(db, article_path, id);
        setDoc(
          docRef,
          {
            ...JSON.parse(JSON.stringify(obj)),
          },
          { merge: true }
        );
        const userRef = doc(db, "users", profileData?.uid);
        setDoc(
          userRef,
          {
            writings: profileData?.writings
              ? [...profileData?.writings, id]
              : [id],
          },
          { merge: true }
        );
        console.info("Content successfully created.");
        setUploading(false);
        window.open("/dashboard/", "_self");
      } catch (err) {
        setError(err);
        console.error("An error Occurred, Contact developer", err);
      }
    });
  } else {
    setImageError({ message: "Select a file" });
  }
}

export async function handleCreateSeries(
  image,
  image_type,
  id,
  profileData,
  image_path,
  article_path,
  setUploading,
  setImageError,
  setError,
  data
) {
  // image_type === "event" && e.preventDefault();
  if (
    (image_type === "event" && image?.target.files[0] !== undefined) ||
    (image_type === "file" && image !== undefined)
  ) {
    async function up() {
      setUploading(true);
      try {
        const newImageRef = ref(getStorage(), image_path);
        const fileSnapshot = await uploadBytesResumable(
          newImageRef,
          (image_type === "event" && image.target.files[0]) ||
            (image_type === "file" && image)
        );
        if (fileSnapshot && fileSnapshot.state !== "success") {
          throw new Error();
        }

        const publicImageUrl = await getDownloadURL(newImageRef);

        return publicImageUrl;
      } catch (error) {
        setImageError(error.message);
        return undefined;
      }
    }
    Promise.resolve(up()).then((value) => {
      setUploading(false);
      setImageError();
      let obj = JSON.parse(JSON.stringify(data));
      obj.banner = value;
      try {
        const docRef = doc(db, article_path, id);
        setDoc(
          docRef,
          {
            ...JSON.parse(JSON.stringify(obj)),
          },
          { merge: true }
        );
        const userRef = doc(db, "users", profileData?.uid);
        setDoc(
          userRef,
          {
            series_list: profileData?.series_list
              ? [...profileData?.series_list, id]
              : [id],
          },
          { merge: true }
        );
        console.info("Content successfully created.");
        setUploading(false);
        // window.open("/dashboard/", "_self");
      } catch (err) {
        setError(err);
        console.error("An error Occurred, Contact developer", err);
      }
    });
  } else {
    setImageError({ message: "Select a file" });
  }
}

export async function handleSeriesArticleUpload(
  image,
  image_type,
  series_id,
  document_id,
  seriesData,
  image_path,
  series_path,
  setUploading,
  setImageError,
  setError,
  data
) {
  // image_type === "event" && e.preventDefault();
  if (
    (image_type === "event" && image?.target.files[0] !== undefined) ||
    (image_type === "file" && image !== undefined)
  ) {
    async function up() {
      setUploading(true);
      try {
        const newImageRef = ref(getStorage(), image_path);
        const fileSnapshot = await uploadBytesResumable(
          newImageRef,
          (image_type === "event" && image.target.files[0]) ||
            (image_type === "file" && image)
        );
        if (fileSnapshot && fileSnapshot.state !== "success") {
          throw new Error();
        }

        const publicImageUrl = await getDownloadURL(newImageRef);

        return publicImageUrl;
      } catch (error) {
        setImageError(error.message);
        return undefined;
      }
    }
    Promise.resolve(up()).then((value) => {
      setUploading(false);
      setImageError();
      let obj = JSON.parse(JSON.stringify(data));
      obj.banner = value;
      try {
        console.log("series_path:", series_path);
        console.log("series_id:", series_id);
        console.log("document_id:", document_id);
        const subCollectionRef = collection(
          doc(db, series_path, series_id),
          "content-paradox"
        );
        const docRef = doc(subCollectionRef, `${document_id}`);
        console.log("here", docRef?.path);
        setDoc(
          docRef,
          {
            ...JSON.parse(JSON.stringify(obj)),
          },
          { merge: true }
        )
          .then(() => {
            console.log("Document successfully written!");
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
        const seriesRef = doc(db, series_path, series_id);
        setDoc(
          seriesRef,
          {
            worshipers: seriesData?.worshipers
              ? [...seriesData?.worshipers, document_id]
              : [document_id],
          },
          { merge: true }
        );
        console.info("Content successfully created.");
        setUploading(false);
        setError();
        // window.open("/dashboard/", "_self");
      } catch (err) {
        setError(err);
        console.error("An error Occurred, Contact developer", err);
      }
    });
  } else {
    setImageError({ message: "Select a file" });
  }
}
