import React, { useEffect } from "react";
import useApp from "../store";
import BlogWriter from "../views/BlogWriter";
import { useNavigate } from "react-router-dom";

export default function NormalContentWriter() {
  const { loggedInStatus, profileData } = useApp();
  const navigate = useNavigate();

  useEffect(() => {
    !loggedInStatus && navigate("/join");
  }, [loggedInStatus]);

  return (
    <div>
      {(profileData?.userType === "monopolist" ||
        profileData?.userType === "fascist") && (
        <>
          <BlogWriter />
        </>
      )}
    </div>
  );
}
