import React, { useEffect, useState } from "react";
import { db } from "../utils/firebase";
import useApp from "../store";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import BlogWriter from "../views/BlogWriter";
import { BsPlus } from "react-icons/bs";

export default function Dashboard() {
  const { profileData, loggedInStatus } = useApp();
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [InDB, setInDB] = useState(false);
  const [Loading, setLoading] = useState(false);

  function getStarted() {
    try {
      const docRef = doc(db, "users", profileData?.uid);
      setDoc(
        docRef,
        {
          ...profileData,
          userType: "",
          loved: [],
          writings: [],
        },
        { merge: true }
      );
      console.info("Database setup Successfully.");
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    setLoading(true);
    const get = async () => {
      const docRef = doc(db, "users", profileData?.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setInDB(true);
        const data = docSnap.data();
        setData(data);
        console.info("Your data found properly in our system.");
      } else {
        console.info("your user profile is not properly set");
        setInDB(false);
        console.info("Setting database....");
        getStarted();
        get();
      }
    };
    profileData?.uid && get();
  }, []);

  useEffect(() => {
    !loggedInStatus && navigate("/join");
  }, [loggedInStatus]);

  return (
    <main className="ahj-dashboard">
      <div className="container">
        <div
          className="card"
          role="button"
          tabIndex={0}
          onClick={() => navigate("/dashboard/write/")}
        >
          <div className="banner">
            <BsPlus />
          </div>
          <div className="content">
            <h2>Write an article</h2>
          </div>
        </div>
        <div
          className="card"
          role="button"
          tabIndex={0}
          onClick={() => navigate("/dashboard/series/create")}
        >
          <div className="banner">
            <BsPlus />
          </div>
          <div className="content">
            <h2>Start a Series</h2>
          </div>
        </div>
        <div
          className="card"
          role="button"
          tabIndex={0}
          onClick={() => navigate("/dashboard/series/")}
        >
          <div className="banner">
            <BsPlus />
          </div>
          <div className="content">
            <h2>See my Series</h2>
          </div>
        </div>
      </div>
    </main>
  );
}
