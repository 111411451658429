import React, { useState } from "react";
import useApp from "../store";
import { encodeTitleToURL } from "../utils/functions/URLEncoder";
import { handleArticleUpload } from "../utils/functions";
import { BsPlus, BsTrash } from "react-icons/bs";

export default function BlogWriter() {
  const { profileData } = useApp();

  const basicObj = {
    variant: "p",
    content: undefined,
    cite: undefined,
    classNames: undefined,
  };

  const [Title, setTitle] = useState();
  const [Cover, setCover] = useState();
  const [CoverCredits, setCoverCredits] = useState();
  const [Language, setLanguage] = useState("english");
  const [Type, setType] = useState();
  const [BodyData, setBodyData] = useState([
    { ...JSON.parse(JSON.stringify(basicObj)) },
  ]);

  // relative states
  const [Focused, setFocused] = useState();
  const [Uploading, setUploading] = useState(false);
  const [ImageError, setImageError] = useState(false);
  const [Error, setError] = useState(false);

  const variants = [
    { text: "H1", variant: "h2" },
    { text: "H2", variant: "h3" },
    { text: "H3", variant: "h4" },
    { text: "H4", variant: "h5" },
    { text: "H5", variant: "h6" },
    { text: "Text", variant: "p" },
    { text: "Quote", variant: "quote" },
    { text: "Link", variant: "a" },
    { text: "List", variant: "ul" },
  ];

  function changeBody(field, value, index) {
    let arr = JSON.parse(JSON.stringify([...BodyData]));
    switch (field) {
      case "variant":
        const currentVar = arr[index].variant;
        if (value === "ul") {
          arr[index].variant = value;
          arr[index].content = arr[index]?.content
            ? arr[index]?.content?.split("\n")
            : [];
        } else if (currentVar === "ul") {
          arr[index].content = arr[index]?.content
            ? arr[index]?.content?.join(" ")
            : undefined;
          arr[index].variant = value;
        } else {
          arr[index].variant = value;
        }
        break;
      case "content":
        if (arr[index]?.variant === "ul") {
          arr[index].content = value?.split("\n");
        } else arr[index].content = value;
        break;
      case "cite":
        arr[index].cite = value;
        break;
      case "className":
        arr[index].className = value;
        break;
      case "href":
        arr[index].href = value;
        break;
      case "delete":
        if (index > 0) arr.splice(index, 1);
        break;
      default:
        break;
    }
    setBodyData(arr);
  }

  function handleEnterPress(e, index) {
    e.preventDefault();
    if (e.key === "Enter") {
      // setBodyData([...BodyData, { ...JSON.parse(JSON.stringify(basicObj)) }]);
      // const areas = document.querySelectorAll(".js-change-handle > textarea");
      // console.log(areas[areas.length - 1]);
      // areas[areas.length - 1].focus();
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const id = encodeTitleToURL(Title);
    let obj = {
      title: Title,
      banner: undefined,
      banner_credit: CoverCredits,
      language: Language,
      writer: profileData?.uid,
      id,
      type: Type,
      date: new Date(),
      content: BodyData,
      status: "review",
    };
    try {
      handleArticleUpload(
        Cover,
        "event",
        id,
        profileData,
        `/blog/covers/${id}`,
        "contents-abacas",
        setUploading,
        setImageError,
        setError,
        obj
      );
    } catch (error) {
      setError(error);
      console.error(error);
    }
  }

  return (
    <div className="ahj-blogWrite-container">
      <main>
        <form className="ahj-long-content" onSubmit={handleSubmit}>
          <div className="element">
            <label htmlFor="article_language">Select Language:</label>
            <select
              name="article_language"
              id="article_language"
              value={Language}
              onChange={(e) => setLanguage(e.target.value)}
              required
            >
              <option value="english">English</option>
              <option value="bangla">Bangla</option>
            </select>
          </div>
          <div className="element type">
            <label htmlFor="article_type">Write Category:</label>
            <input
              type="text"
              value={Type}
              onChange={(e) => setType(e.target.value)}
              required
              placeholder="Politics"
            />
          </div>
          <div
            className="background"
            onFocus={() => setFocused({ value: "image" })}
          >
            {Cover?.target?.files[0] ? (
              <img
                src={
                  Cover?.target?.files[0] &&
                  URL.createObjectURL(Cover.target.files[0])
                }
                alt={Title}
              />
            ) : (
              <p>Upload a cover in .webp format</p>
            )}
            <input
              type="file"
              name="blog_cover"
              id="blog_cover"
              required={Cover ? true : false}
              accept="image/webp"
              onChange={(e) => setCover(e)}
            />
          </div>
          <div className="element title">
            <input
              type="text"
              required
              value={Title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter your title here..."
              onFocus={() => setFocused({ value: "title" })}
            />
          </div>
          {React.Children.toArray(
            BodyData?.map((obj, index) =>
              obj?.variant === "ul" ? (
                <div
                  className={`element body js-change-handle ${obj?.variant}`}
                >
                  <ul>
                    {obj?.content?.length > 0 ? (
                      React.Children.toArray(
                        obj?.content?.map((li) => <li>{li}</li>)
                      )
                    ) : (
                      <li className="placeholder">Start typing...</li>
                    )}
                  </ul>
                  <textarea
                    name="blog_content"
                    id="blog_content"
                    value={obj?.content?.join("\n")}
                    placeholder="Start typing..."
                    onFocus={() => setFocused({ value: "body", index })}
                    onKeyUp={(e) => handleEnterPress(e, index)}
                    onChange={(e) =>
                      e.key !== "Enter" &&
                      changeBody("content", e.target.value, index)
                    }
                    required
                  ></textarea>
                </div>
              ) : (
                <div
                  className={`element body js-change-handle ${obj?.variant}`}
                >
                  <textarea
                    name="blog_content"
                    id="blog_content"
                    value={obj?.content}
                    placeholder="Start typing..."
                    onFocus={() => setFocused({ value: "body", index })}
                    onKeyUp={(e) => handleEnterPress(e, index)}
                    onChange={(e) =>
                      e.key !== "Enter" &&
                      changeBody("content", e.target.value, index)
                    }
                    required
                  ></textarea>
                </div>
              )
            )
          )}
          <button
            className="add"
            type="button"
            onClick={() => {
              setBodyData([
                ...BodyData,
                { ...JSON.parse(JSON.stringify(basicObj)) },
              ]);
              setFocused({ value: "body", index: BodyData?.length });
            }}
          >
            <BsPlus /> Add
          </button>

          <button type="submit" className="submit" disabled={Uploading}>
            {Uploading ? "Uploading..." : "Add To Review"}
          </button>
          {(ImageError || Error) && (
            <div className="error">
              <p>The Following Errors Occurred:</p>
              <ul>
                {ImageError && <li>{ImageError?.message}</li>}
                {Error && <li>{Error?.message}</li>}
              </ul>
            </div>
          )}
        </form>
      </main>
      <aside>
        {
          {
            image: (
              <>
                <div className="element">
                  <label htmlFor="cover_credit">
                    Banner content credit:(If taken content from anyone)
                  </label>
                  <input
                    type="text"
                    name="cover_credit"
                    id="cover_credit"
                    value={CoverCredits}
                    onChange={(e) => setCoverCredits(e.target.value)}
                    placeholder="ProthomAlo, Antik Mahmud, ..."
                  />
                </div>
              </>
            ),
            body: (
              <>
                <div className="element">
                  <label htmlFor="blog_body_type">Select Type</label>
                  <select
                    name="blog_body_type"
                    id="blog_body_type"
                    value={BodyData[Focused?.index]?.variant}
                    onChange={(e) =>
                      changeBody("variant", e.target.value, Focused?.index)
                    }
                  >
                    {React.Children.toArray(
                      variants.map((op) => (
                        <option value={op?.variant}>{op?.text}</option>
                      ))
                    )}
                  </select>
                </div>
                {BodyData[Focused?.index]?.variant === "quote" && (
                  <div className="element">
                    <label htmlFor="quote_cite">Add Reference URL:</label>
                    <input
                      type="url"
                      name="quote_cite"
                      id="quote_cite"
                      value={BodyData[Focused?.index]?.cite}
                      onChange={(e) =>
                        changeBody("cite", e.target.value, Focused?.index)
                      }
                      placeholder="https://example.com"
                    />
                  </div>
                )}
                {BodyData[Focused?.index]?.variant === "a" && (
                  <div className="element">
                    <label htmlFor="link_url">Add Link</label>
                    <input
                      type="url"
                      name="link_url"
                      id="link_url"
                      value={BodyData[Focused?.index]?.href}
                      onChange={(e) =>
                        changeBody("href", e.target.value, Focused?.index)
                      }
                      placeholder="https://example.com"
                    />
                  </div>
                )}
                {Focused?.index > 0 && (
                  <div className="element">
                    <button
                      onClick={() => {
                        if (Focused?.index > 0) {
                          changeBody("delete", 0, Focused?.index);
                          setFocused();
                        }
                      }}
                    >
                      <BsTrash /> Delete
                    </button>
                  </div>
                )}
              </>
            ),
          }[Focused?.value]
        }
      </aside>
    </div>
  );
}
