import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { dummyData } from "../data/data";
import ArticleBody from "../components/ArticleBody";
import { encodeTitleToURL } from "../utils/functions/URLEncoder";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import { Helmet } from "react-helmet";
import useApp from "../store";

export default function SeriesArticleEnglish() {
  const { profileData } = useApp();
  const path = useParams();
  const seriesHandle = encodeTitleToURL(path.seriesID);
  const contentHandle = encodeTitleToURL(path.did);
  console.log(seriesHandle, contentHandle);

  // const data = dummyData;

  const [data, setData] = useState();
  const [UserData, setUserData] = useState();

  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    const get = async () => {
      const specificDocRef = doc(
        db,
        "series-paradox", // Parent collection
        seriesHandle, // Parent document ID
        "content-paradox", // Subcollection name
        contentHandle // Subcollection document ID
      );
      getDoc(specificDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            setData(data);
          } else {
            console.error("No such document!");
          }
        })
        .catch((error) => {
          console.error("Error reading document: ", error);
        });
    };
    contentHandle && get();
  }, [contentHandle, seriesHandle]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={data?.content?.[0]?.content?.content}
        />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />

        <meta property="og:title" content={`${data?.title} | Rajnety`} />
        <meta
          property="og:description"
          content={data?.content?.[0]?.content?.content}
        />
        <meta property="og:image" content={data?.banner} />
        <meta property="og:url" content="https://rajnety.com" />
        <meta property="og:type" content="article" />
        <meta name="twitter:title" content={`${data?.title} | Rajnety`} />
        <meta
          name="twitter:description"
          content={data?.content?.[0]?.content?.content}
        />
        <meta name="twitter:image" content={data?.banner} />
        <meta name="twitter:card" content="summary_large_image" />

        <title>{`${data?.title} | Rajnety`}</title>
      </Helmet>
      {profileData?.userType && profileData?.userType === "monopolist" ? (
        <ArticleBody data={data} />
      ) : data?.status === "published" ? (
        <ArticleBody data={data} />
      ) : (
        <div
          style={{
            minHeight: "calc(100dvh - 100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Stay tuned, Coming Soon...
        </div>
      )}
    </>
  );
}
