import React from "react";
import { useParams } from "react-router-dom";
import { dummyData } from "../data/data";
import ArticleBody from "../components/ArticleBody";

export default function ArticleBangla() {
  const path = useParams();
  const contentHandle = path.did;
  const data = dummyData;
  return <ArticleBody className={"ahj-font-noto-serif-bengali"} data={data} />;
}
