import React, { useEffect, useState } from "react";
import useApp from "../store";
import { useNavigate, useParams } from "react-router-dom";
import { collection, doc, getDocs } from "firebase/firestore";
import { db } from "../utils/firebase";
import LoadingComponent from "../views/LoadingComponent";
import { encodeTitleToURL } from "../utils/functions";
import Card from "../components/Card";

export default function SeriesContentView() {
  const { loggedInStatus, profileData } = useApp();
  const navigate = useNavigate();
  const path = useParams();
  const seriesHandle = encodeTitleToURL(path.seriesId);
  const contentDocumentName = "series-paradox";
  const contentDocumentNameSecondary = "content-paradox";

  const [data, setData] = useState();
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    try {
      async function dataCall() {
        let data = [];
        const subCollectionRef = collection(
          doc(db, contentDocumentName, seriesHandle),
          contentDocumentNameSecondary
        );
        try {
          const snapshot = await getDocs(subCollectionRef);
          snapshot.docs.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id });
          });
        } catch (error) {
          console.error("Error getting documents: ", error);
        }
        return data;
      }

      dataCall().then((value) => {
        setData(value);
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
    }
  }, [profileData?.userType, seriesHandle]);

  useEffect(() => {
    !loggedInStatus && navigate("/join");
  }, [loggedInStatus]);

  return (
    <>
      <main className="ahj-series_view">
        {data && Array.isArray(data) && data.length > 0 ? (
          <div className="content">
            <Card type="series_article_add" data={seriesHandle} language="" />
            {React.Children.toArray(
              data.map((card) => (
                <Card
                  data={card}
                  type="series_article_preview_2"
                  language={card?.language}
                />
              ))
            )}
          </div>
        ) : (
          <>
            <p style={{ paddingBottom: "24px" }}>No series found</p>
            <Card type="series_article_add" data={seriesHandle} language="" />
          </>
        )}
      </main>
      <LoadingComponent state={Loading} />
    </>
  );
}
