import React, { useEffect, useState } from "react";
import { FaShare } from "react-icons/fa";

export default function ArticleBody({ className = "", data }) {
  const [References, setReferences] = useState();

  useEffect(() => {
    function citeings() {
      let arr = JSON.parse(JSON.stringify(data?.content));
      let res = arr.map((el) =>
        !(el?.cite === undefined || el?.cite === "") ? el.cite : ""
      );
      console.log(res);

      setReferences([...new Set(res.filter((el) => el !== ""))]);
    }
    data?.content &&
      Array.isArray(data.content) &&
      data.content.length > 0 &&
      citeings();
  }, [data?.content]);

  console.log(References);

  return (
    <article className={`ahj-long-content ${className}`}>
      {data?.banner && (
        <div
          className="background"
          // style={{ backgroundImage: data?.banner }}
        >
          <img src={data?.banner} alt={data?.title} />
          {data?.banner_credit && (
            <p className="image-credit">{data?.banner_credit}</p>
          )}
        </div>
      )}
      <div className="content">
        <h1 className={``}>{data?.title}</h1>
        {React.Children.toArray(
          data?.content?.map((element) =>
            element?.variant === "h2" ? (
              <h2>{element?.content}</h2>
            ) : element?.variant === "h3" ? (
              <h3>{element?.content}</h3>
            ) : element?.variant === "h4" ? (
              <h4>{element?.content}</h4>
            ) : element?.variant === "quote" ? (
              <blockquote cite={element?.cite}>
                <q>{element?.content}</q>
              </blockquote>
            ) : element?.variant === "a" ? (
              <a
                href={element?.href ? element?.href : element?.content}
                target="_blank"
                rel="noopener noreferrer"
              >
                {element?.content}
              </a>
            ) : element?.variant === "ul" ? (
              <ul>
                {React.Children.toArray(
                  element?.content?.map((li) => <li>{li}</li>)
                )}
              </ul>
            ) : (
              <p>{element?.content}</p>
            )
          )
        )}

        {References && Array.isArray(References) && References.length > 0 && (
          <>
            <h4 className="ref">References:</h4>
            <div className="refs">
              {React.Children.toArray(
                References?.map((refEl) => (
                  <a href={refEl} target="_blank" rel="noopener noreferrer">
                    {refEl}
                  </a>
                ))
              )}
            </div>
          </>
        )}
      </div>
      <button
        className="share-button"
        onClick={() => navigator.share({ url: `${window?.location?.href}` })}
      >
        <FaShare />
      </button>
    </article>
  );
}
