import React, { useEffect } from "react";
import CreateSeriesForm from "../views/CreateSeriesForm";
import useApp from "../store";
import { useNavigate } from "react-router-dom";

export default function CreateSeries() {
  const { loggedInStatus } = useApp();
  const navigate = useNavigate();

  useEffect(() => {
    !loggedInStatus && navigate("/join");
  }, [loggedInStatus]);

  return (
    <main className="ahj-createSeries">
      <CreateSeriesForm />
    </main>
  );
}
