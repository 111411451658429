export function encodeTitleToURL(str) {
  return str
    .replaceAll(" ", "%20")
    .replaceAll("!", "%21")
    .replaceAll("?", "%3F")
    .replaceAll(".", "%2E")
    .replaceAll(".", "%2E")
    .replaceAll("'", "%27")
    .replaceAll(":", "%3A")
    .replaceAll(";", "%3B");
  // .replaceAll(".", "%2E")
  // .replaceAll(".", "%2E")
  // .replaceAll("?", "%3F");
}
