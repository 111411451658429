import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { dummyData } from "../data/data";
import ArticleBody from "../components/ArticleBody";
import { encodeTitleToURL } from "../utils/functions/URLEncoder";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import { contentDocumentName } from "../settings";
import { Helmet } from "react-helmet";

export default function ArticleEnglish() {
  const path = useParams();
  const contentHandle = encodeTitleToURL(path.did);
  // const data = dummyData;

  const [data, setData] = useState();
  const [UserData, setUserData] = useState();

  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    const get = async () => {
      const docRef = doc(db, contentDocumentName, contentHandle);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setData(data);
      } else {
        console.error("No such document!");
      }
    };
    contentHandle && get();
  }, [contentHandle]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={data?.content?.[0]?.content?.content}
        />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />

        <meta property="og:title" content={`${data?.title} | Rajnety`} />
        <meta
          property="og:description"
          content={data?.content?.[0]?.content?.content}
        />
        <meta property="og:image" content={data?.banner} />
        <meta property="og:url" content="https://rajnety.com" />
        <meta property="og:type" content="article" />
        <meta name="twitter:title" content={`${data?.title} | Rajnety`} />
        <meta
          name="twitter:description"
          content={data?.content?.[0]?.content?.content}
        />
        <meta name="twitter:image" content={data?.banner} />
        <meta name="twitter:card" content="summary_large_image" />

        <title>{`${data?.title} | Rajnety`}</title>
      </Helmet>
      <ArticleBody data={data} />;
    </>
  );
}
