import React from "react";
import { BsPlus } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export default function Card({
  type = "",
  data = {},
  language = "english" || "bangla",
  optionalFunction = () => {},
}) {
  const navigate = useNavigate();

  switch (type) {
    case "loading":
      return (
        <div className={`ahj-card-loading-standard`}>
          <div className="image"></div>
          <h4 className="title">
            <span className="title__line"></span>
          </h4>
          <p className="description">
            <span></span>
            <span></span>
            <span></span>
          </p>
          <p className="link">
            <span></span>
          </p>
          {/* <a href={`/discussion/${data?.language}/${data?.id}`}>
            {data?.title}
          </a> */}
        </div>
      );
    case "series_article_preview_2":
      return (
        <div
          className={`ahj-card-standard ${
            language === "bangla"
              ? "ahj-font-noto-serif-bengali " + language
              : ""
          }`}
        >
          <div className="image">
            {data?.banner ? (
              <img
                src={data?.banner}
                alt={
                  data?.thumbnailDescription ||
                  `${data?.title?.substring(0, 10)}... banner`
                }
              />
            ) : (
              <div className="custom">{`${data?.title?.substring(
                0,
                40
              )}...`}</div>
            )}
          </div>
          <h4 className="title">
            <span className="title__line"></span>
            {data?.title}
          </h4>
          <p className="description">
            {`${data?.content?.[0]?.content?.substring(0, 97)}...`}
          </p>
          <p className="link">Read {`>`}</p>
          {/* <a href={`/preview/series/${data?.language}/${data?.id}`}>
            {data?.title}
          </a> */}
        </div>
      );
    case "series_article_preview":
      return (
        <div
          className={`ahj-card-standard ${
            language === "bangla"
              ? "ahj-font-noto-serif-bengali " + language
              : ""
          }`}
          role="button"
          tabIndex={0}
          onClick={() => {
            optionalFunction();
            navigate(`/dashboard/series/view/${data?.id}`);
          }}
        >
          <div className="image">
            {data?.banner ? (
              <img
                src={data?.banner}
                alt={
                  data?.thumbnailDescription ||
                  `${data?.title?.substring(0, 10)}... banner`
                }
              />
            ) : (
              <div className="custom">{`${data?.title?.substring(
                0,
                40
              )}...`}</div>
            )}
          </div>
          <h4 className="title">
            <span className="title__line"></span>
            {data?.title}
          </h4>
          <p className="description">
            {`${data?.overview?.substring(0, 97)}...`}
          </p>
          <p className="link">View Contents {`>`}</p>
          {/* <a href={`/dashboard/series/view/${data?.id}`}>{data?.title}</a> */}
        </div>
      );
    case "series_article_add":
      return (
        <div
          className={`ahj-card-${type}`}
          role="button"
          tabIndex={0}
          onClick={() => navigate(`/dashboard/series/write/${data}`)}
        >
          <div className="banner">
            <BsPlus />
          </div>
          <div className="content">
            <h2>Add An Article to the Series</h2>
          </div>
        </div>
      );
    case "series_card":
      return (
        <div
          className={`ahj-card-standard ${
            language === "bangla"
              ? "ahj-font-noto-serif-bengali " + language
              : ""
          }`}
          role="button"
          tabIndex={0}
          onClick={() => navigate(`/series/${data?.language}/${data?.id}`)}
        >
          <div className="image">
            {data?.banner ? (
              <img
                src={data?.banner}
                alt={
                  data?.thumbnailDescription ||
                  `${data?.title?.substring(0, 10)}... banner`
                }
              />
            ) : (
              <div className="custom">{`${data?.title?.substring(
                0,
                40
              )}...`}</div>
            )}
          </div>
          <h4 className="title">
            <span className="title__line"></span>
            {data?.title}
          </h4>
          <p className="description">
            {`${data?.overview?.substring(0, 97)}...`}
          </p>
          <p className="link">View Contents {`>`}</p>
          {/* <a href={`/series/${data?.language}/${data?.id}`}>{data?.title}</a> */}
        </div>
      );
    case "standard_series":
      return (
        <div
          className={`ahj-card-standard ${
            language === "bangla"
              ? "ahj-font-noto-serif-bengali " + language
              : ""
          }`}
          role="button"
          tabIndex={0}
          onClick={() =>
            navigate(
              `/discussion/series/${data?.language}/${data?.series}/${data.id}`
            )
          }
        >
          <div className="image">
            {data?.banner ? (
              <img
                src={data?.banner}
                alt={
                  data?.thumbnailDescription ||
                  `${data?.title?.substring(0, 10)}... banner`
                }
              />
            ) : (
              <div className="custom">{`${data?.title?.substring(
                0,
                40
              )}...`}</div>
            )}
          </div>
          <h4 className="title">
            <span className="title__line"></span>
            {data?.title}
          </h4>
          <p className="description">
            {`${data?.content?.[0]?.content?.substring(0, 97)}...`}
          </p>
          <p className="link">Read more {`>`}</p>
          {/* <a href={`/discussion/${data?.language}/${data?.id}`}>
            {data?.title}
          </a> */}
        </div>
      );
    case "standard":
    default:
      return (
        <div
          className={`ahj-card-standard ${
            language === "bangla"
              ? "ahj-font-noto-serif-bengali " + language
              : ""
          }`}
          role="button"
          tabIndex={0}
          onClick={() => navigate(`/discussion/${data?.language}/${data?.id}`)}
        >
          <div className="image">
            {data?.banner ? (
              <img
                src={data?.banner}
                alt={
                  data?.thumbnailDescription ||
                  `${data?.title?.substring(0, 10)}... banner`
                }
              />
            ) : (
              <div className="custom">{`${data?.title?.substring(
                0,
                40
              )}...`}</div>
            )}
          </div>
          <h4 className="title">
            <span className="title__line"></span>
            {data?.title}
          </h4>
          <p className="description">
            {`${data?.content?.[0]?.content?.substring(0, 97)}...`}
          </p>
          <p className="link">Read more {`>`}</p>
          {/* <a href={`/discussion/${data?.language}/${data?.id}`}>
            {data?.title}
          </a> */}
        </div>
      );
  }
}
