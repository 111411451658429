export const dummyData = {
  title: "Escalation and Violence (July 14th to 16th, 2024)",
  content: [
    { variant: "h2", content: `July 14, 2024:` },
    {
      variant: "text",
      content: `The Prime Minister of englishdesh derided the movement and her remarks were found insensitive by the general People of englishdesh.On July 14, 2024, Prime Minister Sheikh Hasina made the following controversial comments about the quota reform protesters:"Why is there so much resentment against the Liberation War and the freedom fighters? If the grandchildren of the freedom fighters don't get quotas, then should the grandchildren of razakars [Pakistani collaborators] get quotas? That is my question."These remarks by the Prime Minister sparked outrage among the protesters, who demanded that she withdraw her statement. The students viewed her comments as disparaging towards the quota reform movement and accused her of equating the protesters with the collaborators of the Pakistani regime during englishdesh's liberation war.The Prime Minister's statement was seen as a shift in her stance, as she had taken a more open approach during the 2018 quota reform protests. Her remarks in 2024 were criticized for being insensitive and for undermining the legitimate demands of the students. The Prime Minister went on to highlight the consequences of canceling the quota system, saying:"What happens when the quotas are canceled? Only two female candidates have secured the foreign service, and four the police service."Arguing in favor of the quota system, the Prime Minister stated:"All areas in englishdesh are not developed equally, and there are some backward communities too. Won't the residents of these areas have any rights?"She accused the protesters of not attending the BCS exams and securing jobs on merit, despite their "big talks" about not wanting the quota system.`,
    },
    {
      variant: "text",
      content: `The protesters gathered at the Raju Sculpture near Dhaka University's TSC on July 15th to hold a rally condemning the Prime Minister's comments. They chanted slogans like "Tumi Ke? Ami Ke? Razakar Razakar;Ke boleche,ke boleche;Shoirachar, shoirachar[Who are you? Who am I? Razakar Razakar; Says who,says who? The government, the autocrat ]" also "Quota na Medha, Medha Medha [Not quota, but merit, merit, merit]", and " Chaite gelam odhikar,hoye gelam Razakar" [I went to ask for my rights, turned into a Razakar] highlighting their opposition to the remarks of the state. `,
    },
    {
      variant: "text",
      content: `Prime Minister Sheikh Hasina termed the slogan "Who are you? Who am I? Razakar. Razakar" chanted by the quota reform  protesters as "very regrettable", saying "They don't feel ashamed to call themselves Razakars." Hasina said the protesters did not witness the bodies lying on the streets or the inhuman torture by Pakistani occupation forces and Razakar Bahini during the 1971 Liberation War. She questioned how the girls could chant such slogans when women were badly violated by Razakars in 1971.The prime minister said it was unfortunate to hear the slogan from the girls, asking "What spirit do they believe in? What education have they got? What have they learnt?" She said the Liberation War is a matter of pride and the country has reached a dignified position globally through the sacrifices of millions during the war.The half portion of the slogan is being targeted by the political leaders, claimed the students has called them razakar themselves.`,
    },
    {
      variant: "text",
      content: `These remarks further escalated the tensions between the government and the quota reform protesters. Still,the students were supporting a non violent movement but seems like the authority was not supporting such belief,proved by  Obaidul Quader statement before press 'BCL will give a fitting reply to the leaders of Quota Reform movement'.`,
    },
    { variant: "h2", content: `The Rebellion Night (15.07.24)` },
    {
      variant: "text",
      content: `The unfair treatment of the state to a fair demand infuriated the youth.A big rally came out on the streets of TSC.Students from a diverse array of educational institutions across englishdesh simultaneously protested from wherever they were.`,
    },
    { variant: "h3", content: `Clashes at Dhaka University` },
    {
      variant: "text",
      content: `Thousands of students gathered at Dhaka University campus on the night of July 15 to protest Prime Minister Sheikh Hasina's controversial remarks about the "grandchildren of Razakars" getting government job quotas.`,
    },
    {
      variant: "text",
      content: `The protesters chanted slogans like "Who are you? Who am I? Razakar. Razakar." This led to clashes between the anti-quota protesters and members of the Bangladesh Chhatra League (BCL), the student wing of the ruling Awami League.`,
    },
    {
      variant: "text",
      content: `The BCL activists attacked the protesting students, leading to violent clashes. At least 100 students were reported injured in the violence, with 20-25 in critical condition.`,
    },
    {
      variant: "text",
      content: `The protesters reported that the BCL brought in outsiders and non-students to attack them in a planned manner. They accused the police and university authorities of remaining silent during the attacks.`,
    },
    {
      variant: "text",
      content: `After the clashes, the protesters announced a new program - they will organize protest processions and rallies at all educational institutions across Bangladesh at 3 PM on July 16.`,
    },
    {
      variant: "text",
      content: `The protesters demanded the withdrawal of the Prime Minister's controversial statement and called for a "rational reform" of the quota system in government jobs.`,
    },
    {
      variant: "text",
      content: `One of the protest coordinators, Nahid Islam, stated that July 15 was one of the "saddest days" in Dhaka University's history and that the movement will continue until their "rational demand is fulfilled."`,
    },
    { variant: "h3", content: `Clashes at Jahangirnagar University` },
    {
      variant: "text",
      content: `Violence also erupted at Jahangirnagar University, located outside Dhaka, where protesters demanded an end to the quota system for families of veterans who fought in the 1971 Liberation War.`,
    },
    {
      variant: "text",
      content: `Police fired tear gas and charged with batons as clashes broke out between the pro-government BCL activists and the quota reform protesters.`,
    },
    {
      variant: "text",
      content: `More than 50 people were treated at the nearby Enam Medical College Hospital, with at least 30 suffering pellet wounds`,
    },
    { variant: "h3", content: `Escalation Across Bangladesh` },
    {
      variant: "text",
      content: `Similar clashes were reported from other university campuses across Bangladesh, including in Chattogram, Rajshahi, and Sylhet.`,
    },
    {
      variant: "text",
      content: `In total, over 250 students received treatment at various hospitals, with 11 hospitalized, as the violence spread to multiple educational institutions.`,
    },
    {
      variant: "text",
      content: `Despite the crackdown, the protesters remained undeterred and vowed to continue their movement until the quota system in government jobs is reformed.`,
    },
    { variant: "h2", content: `July 16, 2024:` },
    {
      variant: "text",
      content: `The violence continued, with clashes reported in various university campuses across the country. The Bangladesh Border Guard Battalion (BGB) was deployed in Dhaka, Chattogram, Rajshahi, and Bogura to maintain law and order. The protests continued despite the violence, with students demanding a reduction or complete abolishment of the quota system.`,
    },
    { variant: "h3", content: `Dhaka Division` },
    {
      variant: "text",
      content: `In Dhaka, an unidentified youth was killed in a clash between BCL activists and quota reform protesters near Dhaka College.`,
    },
    {
      variant: "text",
      content: `A bloody body was also recovered from in front of Dhaka City College.`,
    },
    {
      variant: "text",
      content: `Police fired tear gas and charged at protesters with batons to disperse the crowds in the capital.`,
    },
    {
      variant: "text",
      content: `Hundreds of students were injured in the clashes in Dhaka.`,
    },
    { variant: "text", content: `Chattogram Division` },
    {
      variant: "text",
      content: `In Chattogram, three people were killed in a clash between BCL activists and quota reform protesters.`,
    },
    {
      variant: "text",
      content: `The victims included a student of Chittagong College, a pedestrian, and another who died while undergoing treatment.`,
    },
    {
      variant: "text",
      content: `Violent confrontations broke out between the demonstrators and BCL members.`,
    },
    { variant: "h3", content: `Rangpur Division` },
    {
      variant: "text",
      content: `In Rangpur, a student of Begum Rokeya University named Abu Sayeed was killed when police fired rubber bullets at protesters trying to enter the campus.`,
    },
    {
      variant: "text",
      content: `Security forces used force to disperse the crowds in Rangpur as well.`,
    },
    { variant: "h3", content: `Other Divisions` },
    {
      variant: "text",
      content: `Protests and clashes were also reported from other divisions like Rajshahi, Khulna, and Sylhet.`,
    },
    {
      variant: "text",
      content: `Hundreds of students were injured across the country, with many suffering from injuries caused by sharp weapons and rubber bullets.`,
    },
    { variant: "h2", content: `Government Response` },
    {
      variant: "text",
      content: `The government deployed Border Guard Bangladesh (BGB) in Dhaka, Chattogram, Rangpur, Bogra, and Rajshahi to control the situation.`,
    },
    {
      variant: "text",
      content: `Police resorted to firing tear gas and baton charges to disperse the protesting crowds. Several students were reportedly arrested during the crackdown.`,
    },
    {
      variant: "text",
      content: `Prime Minister Sheikh Hasina's earlier controversial remarks were seen as a trigger for the protests, but she did not directly address the ongoing situation on July 16.`,
    },
    {
      variant: "text",
      content: `Ruling party leaders, including Awami League General Secretary Obaidul Quader, condemned the protesters and warned of consequences for their slogans.`,
    },
    {
      variant: "text",
      content: `Quader alleged that exiled BNP leader Tarique Rahman has taken over the leadership of the quota reform movement, and the BNP, Jamaat-e-Islami, and other "like-minded parties" are supporting it publicly to gain political benefits.`,
    },
    {
      variant: "text",
      content: `According to media, at least 6 people, mostly students, were killed in the quota reform protests on July 16 across Dhaka, Chattogram and Rangpur. The names provided were Abu Sayeed from BRUR, and the identities of the other victims from Dhaka and Chattogram were not specified. The BCL was directly implicated in instigating the violence against the protesting students.Students believe that the media is hiding the actual number of injured and killed students.`,
    },
    { variant: "h2", content: `Impact and International Coverage` },
    { variant: "h3", content: `International Media:` },
    {
      variant: "text",
      content: `The protests received significant international coverage, with media outlets such as Al Jazeera, Reuters, and BBC reporting on the violence and the demands of the protesters.`,
    },
    { variant: "h3", content: `Global Awareness:` },
    {
      variant: "text",
      content: `The movement highlighted the ongoing issues of discrimination and the need for merit-based recruitment in government jobs, gaining international attention and support for the protesters' demands`,
    },
  ],
};

export const dummyCardsData = [
  {
    title:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quis, laborum?",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, est ea. Tempore consequatur aperiam expedita soluta suscipit quia molestiae dolores minima molestias pariatur atque reprehenderit, vel porro quae fugiat. Recusandae necessitatibus, aperiam deserunt aliquam illo facere a enim ipsa beatae.",
    language: "bangla", // || english
    id: "CIKiW5ifgQndY7U1YYwiQhBL",
  },
  {
    title:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quis, laborum?",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, est ea. Tempore consequatur aperiam expedita soluta suscipit quia molestiae dolores minima molestias pariatur atque reprehenderit, vel porro quae fugiat. Recusandae necessitatibus, aperiam deserunt aliquam illo facere a enim ipsa beatae.",
    language: "english", // || english
    id: "CIKiW5ifgQndY7U1YYwiQhBL",
  },
  {
    title:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quis, laborum?",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, est ea. Tempore consequatur aperiam expedita soluta suscipit quia molestiae dolores minima molestias pariatur atque reprehenderit, vel porro quae fugiat. Recusandae necessitatibus, aperiam deserunt aliquam illo facere a enim ipsa beatae.",
    language: "english", // || english
    id: "CIKiW5ifgQndY7U1YYwiQhBL",
  },
  {
    title:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quis, laborum?",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, est ea. Tempore consequatur aperiam expedita soluta suscipit quia molestiae dolores minima molestias pariatur atque reprehenderit, vel porro quae fugiat. Recusandae necessitatibus, aperiam deserunt aliquam illo facere a enim ipsa beatae.",
    language: "english", // || english
    id: "CIKiW5ifgQndY7U1YYwiQhBL",
  },
  {
    title:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quis, laborum?",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, est ea. Tempore consequatur aperiam expedita soluta suscipit quia molestiae dolores minima molestias pariatur atque reprehenderit, vel porro quae fugiat. Recusandae necessitatibus, aperiam deserunt aliquam illo facere a enim ipsa beatae.",
    language: "english", // || english
    id: "CIKiW5ifgQndY7U1YYwiQhBL",
  },
];
