export function processProfileData(data) {
  let obj = JSON.parse(JSON.stringify(data));

  delete obj["accessToken"];
  delete obj["apiKey"];
  delete obj["appData"];
  delete obj["createdAt"];
  delete obj["auth"];
  delete obj["metadata"];
  delete obj["proactiveRefresh"];
  delete obj["providerData"];
  delete obj["providerId"];
  delete obj["reloadUserInfo"];
  delete obj["stsTokenManager"];
  delete obj["tenantId"];
  // delete obj["userType"];

  return obj;
}
