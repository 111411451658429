import React, { useEffect, useState } from "react";
import Card from "../components/Card";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../utils/firebase";
import useApp from "../store";
import { contentDocumentName } from "../settings";

export default function Home() {
  const [data, setData] = useState();
  const [SeriesData, setSeriesData] = useState();
  const [Loading, setLoading] = useState(false);
  const [SeriesLoading, setSeriesLoading] = useState(false);
  const { profileData } = useApp();

  useEffect(() => {
    setLoading(true);
    try {
      async function dataCall() {
        let data = [];
        let q;
        if (profileData?.userType && profileData?.userType === "monopolist") {
          q = query(
            collection(db, contentDocumentName),
            orderBy("date", "desc")
          );
        } else {
          q = query(
            collection(db, contentDocumentName),
            where("status", "==", "published"),
            orderBy("date", "desc"),
            limit(8)
          );
        }
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id });
        });
        return data;
      }

      Promise.resolve(dataCall()).then((value) => {
        setData(value);
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
    }
  }, [profileData?.userType]);

  useEffect(() => {
    setSeriesLoading(true);
    try {
      async function dataCallSeries() {
        let data = [];
        let q;
        if (profileData?.userType && profileData?.userType === "monopolist") {
          q = query(collection(db, "series-paradox"), orderBy("date", "desc"));
        } else {
          q = query(
            collection(db, "series-paradox"),
            where("status", "==", "published"),
            orderBy("date", "desc"),
            limit(8)
          );
        }
        console.log(q?.type);

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id });
          console.log(doc.data(), doc.id);
        });
        return data;
      }

      Promise.resolve(dataCallSeries()).then((value) => {
        setSeriesData(value);
        setSeriesLoading(false);
      });
    } catch (error) {
      console.error(error);
    }
  }, [profileData?.userType]);

  console.log(SeriesData);

  return (
    <>
      <main className="ahj-home">
        <h1 className="title">Latests</h1>
        <div className="ahj-home-body">
          {!Loading
            ? data &&
              Array.isArray(data) &&
              data.length > 0 &&
              React.Children.toArray(
                data.map((card) => (
                  <Card data={card} type="standard" language={card?.language} />
                ))
              )
            : React.Children.toArray(
                [...Array(8)].map((item) => (
                  <Card type="loading" language={item} />
                ))
              )}
        </div>

        <>
          <h2 className="title">Check Our Latest Series</h2>
          <div className="ahj-home-body">
            {!SeriesLoading
              ? SeriesData &&
                Array.isArray(SeriesData) &&
                SeriesData?.length > 0 &&
                React.Children.toArray(
                  SeriesData.map((card) => (
                    <Card
                      data={card}
                      type="series_card"
                      language={card?.language}
                    />
                  ))
                )
              : React.Children.toArray(
                  [...Array(4)].map((item) => (
                    <Card type="loading" language={item} />
                  ))
                )}
          </div>
        </>
      </main>
    </>
  );
}
