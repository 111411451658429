import React, { useState } from "react";
import useApp from "../store";
import { encodeTitleToURL } from "../utils/functions/URLEncoder";
import { handleCreateSeries } from "../utils/functions";

export default function CreateSeriesForm() {
  const { profileData } = useApp();

  const [Title, setTitle] = useState();
  const [Cover, setCover] = useState();
  const [CoverCredits, setCoverCredits] = useState();
  const [Language, setLanguage] = useState("english");
  const [Type, setType] = useState();
  const [Details, setDetails] = useState();

  // relative states
  const [Focused, setFocused] = useState();
  const [Uploading, setUploading] = useState(false);
  const [ImageError, setImageError] = useState(false);
  const [Error, setError] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    const id = encodeTitleToURL(Title);
    let obj = {
      title: Title,
      banner: undefined,
      banner_credit: CoverCredits,
      language: Language,
      writer: profileData?.uid,
      id,
      type: Type,
      date: new Date(),
      overview: Details,
      status: "review",
    };
    try {
      handleCreateSeries(
        Cover,
        "event",
        id,
        profileData,
        `/series/covers/${id}`,
        "series-paradox",
        setUploading,
        setImageError,
        setError,
        obj
      );
    } catch (error) {
      setError(error);
      console.error(error);
    }
  }

  return (
    <div className="ahj-blogWrite-container">
      <main>
        <form className="ahj-long-content" onSubmit={handleSubmit}>
          <h1>Creating A new Series</h1>
          <div className="element">
            <label htmlFor="article_language">Series Written Language:</label>
            <select
              name="article_language"
              id="article_language"
              value={Language}
              onChange={(e) => setLanguage(e.target.value)}
              required
            >
              <option value="english">English</option>
              <option value="bangla">Bangla</option>
            </select>
          </div>
          <div className="element type">
            <label htmlFor="article_type">Series Category:</label>
            <input
              type="text"
              value={Type}
              onChange={(e) => setType(e.target.value)}
              required
              placeholder="Politics"
            />
          </div>
          <div
            className="background"
            onFocus={() => setFocused({ value: "image" })}
          >
            {Cover?.target?.files[0] ? (
              <img
                src={
                  Cover?.target?.files[0] &&
                  URL.createObjectURL(Cover.target.files[0])
                }
                alt={Title}
              />
            ) : (
              <p>Upload a cover in .webp format</p>
            )}
            <input
              type="file"
              name="blog_cover"
              id="blog_cover"
              required={Cover ? true : false}
              accept="image/webp"
              onChange={(e) => setCover(e)}
            />
          </div>
          <div className="element title">
            <input
              type="text"
              required
              value={Title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter your title here..."
              onFocus={() => setFocused({ value: "title" })}
            />
          </div>
          <div className={`element body js-change-handle text`}>
            <textarea
              name="series_overview"
              id="series_overview"
              value={Details}
              placeholder="Start typing..."
              onFocus={() => setFocused({ value: "body", index: 0 })}
              onChange={(e) => setDetails(e.target.value)}
            ></textarea>
          </div>

          <button type="submit" className="submit" disabled={Uploading}>
            {Uploading ? "Uploading..." : "Add Series To Review"}
          </button>
          {(ImageError || Error) && (
            <div className="error">
              <p>The Following Errors Occurred:</p>
              <ul>
                {ImageError && <li>{ImageError?.message}</li>}
                {Error && <li>{Error?.message}</li>}
              </ul>
            </div>
          )}
        </form>
      </main>
      <aside>
        {
          {
            image: (
              <>
                <div className="element">
                  <label htmlFor="cover_credit">
                    Banner content credit:(If taken content from anyone)
                  </label>
                  <input
                    type="text"
                    name="cover_credit"
                    id="cover_credit"
                    value={CoverCredits}
                    onChange={(e) => setCoverCredits(e.target.value)}
                    placeholder="ProthomAlo, Antik Mahmud, ..."
                  />
                </div>
              </>
            ),
            body: (
              <>
                <p>
                  Add a short overview of your series, which will be shown in
                  the home
                </p>
              </>
            ),
          }[Focused?.value]
        }
      </aside>
    </div>
  );
}
