import React, { useEffect } from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from "../utils/firebase";
import useApp from "../store";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";

export default function Login() {
  const { userLogin, loggedInStatus } = useApp();
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();

  function SignIn() {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;

        const get = async () => {
          const docRef = doc(db, "users", user?.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            return data;
          } else {
            console.error("No such document!");
            return {};
          }
        };
        user?.uid &&
          Promise.resolve(get()).then((value) => {
            userLogin(true, { ...user, ...value }, "");
          });
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        alert("Error: ", errorCode, errorMessage, email, credential);
      });
  }

  useEffect(() => {
    loggedInStatus && navigate("/dashboard/");
  }, []);

  return (
    <main className="ahj-sign">
      <div className="content">
        <h1 className="ahj-font-lato">
          Join our community of writers & researchers
        </h1>
        <button onClick={SignIn} className="ahj-font-lato">
          <span>
            <img src="/assets/icons/google.svg" alt="google icon" />
          </span>
          Continue with Google
        </button>
      </div>
      <div className="graphics">
        <h2 className="ahj-font-anek-bangla">
          <span>রাজ</span>
          নীতি
        </h2>
      </div>
    </main>
  );
}
