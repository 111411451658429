import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <main className="ahj-page-notfound">
      <h1>The page you are looking for is not available.</h1>
      <Link to={`/`}>Homepage &rarr;</Link>
    </main>
  );
}
